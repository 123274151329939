<template>
  <div class="main-container">
    <div class="option-container">
      <div class="btn-container">
        <a-button
            v-for="([targetType, value]) in Object.entries(filterButtonData)"
            :key="targetType"
            :type="getFilterButtonType(targetType)"
            @click="pickType(targetType)"
        >{{ value }}
        </a-button>
      </div>
      <a-button type="primary" ghost @click="createEvent">Создать</a-button>
    </div>
    <div class="events-container">
      <a-table :data-source="events" :columns="eventColumns">
        <template #bodyCell="{column,record}">
          <div class="cell-action__buttons" v-if="column.dataIndex==='actions'">
            <a-button type="primary" @click="router.push({name:'eventEditor',params:{id:record.id}})">Редактировать
            </a-button>
            <a-button type="primary" danger @click="addEventForDelete(record)">Удалить
            </a-button>
          </div>
          <div class="" v-if="column.dataIndex==='eventDateStart'||column.dataIndex==='eventDateEnd'">
            {{
              record[column.dataIndex] ? new Date(record[column.dataIndex]).toLocaleDateString('ru-RU', dateOption) : 'Не выбрано'
            }}
          </div>
        </template>
      </a-table>
    </div>
    <a-modal v-model:visible="deletedEvent" title="Удалить событие: " @ok="onDeleteEvent">
      <p>{{ deletedEvent?.title }}</p>
    </a-modal>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {onBeforeMount, ref, shallowRef, computed} from "vue";

export default {
  name: "EventsListPage",
  setup() {
    const store = useStore();
    const router = useRouter();

    const filterButtonData = {
      lecture: 'Лекции',
      excursion: 'Экскурсии',
      broadcast: 'Трансляции',
      exhibition: 'Выставки',
      record: 'Записи',
      dedicatedTour: 'Живая экскурсия',
      recordedTour: 'Записанная экскурсия',
      vdnh_excursion: 'Экскурсия по ВДНХ',
    };

    let page = ref(1);
    let limit = ref(20);
    let pagination = ref(true);
    let type = ref('lecture');

    const fetchEvents = () => store.dispatch('fetchEvents', {
      page: page.value,
      limit: limit.value,
      type: type.value
    }).then(data => {
      if (!data || data.length < limit.value) {
        pagination.value = false;
      }
    });

    const getFilterButtonType = (incomingType) => {
      return type.value === incomingType ? 'primary' : 'ghost';
    }

    const createEvent = () => {
      sessionStorage.setItem('eventType', type.value);
      router.push({name: 'eventEditor', params: {id: 'new'}});
    }


    onBeforeMount(() => {
      fetchEvents();
    })

    const deletedEvent = shallowRef(null);

    function addEventForDelete(event) {
      deletedEvent.value = event;
    }

    async function onDeleteEvent() {
      await store.dispatch('removeEventById', deletedEvent.value.id);
      await fetchEvents();
      deletedEvent.value = false;
    }


    return {
      filterButtonData,
      events: computed(() => store.getters.events),
      router,
      type,
      createEvent,
      getFilterButtonType,
      addEventForDelete,
      deletedEvent,
      onDeleteEvent,
      pickType: (val) => {
        if (type.value === val) return;
        type.value = val;
        fetchEvents();
      },
      eventColumns: [
        {
          title: 'Название',
          dataIndex: 'title',
        },
        {
          title: 'Дата начала',
          dataIndex: 'eventDateStart',
        },
        {
          title: 'Дата конца',
          dataIndex: 'eventDateEnd',
        },
        {
          title: 'Действия',
          dataIndex: 'actions',
        },
      ],
      dateOption: {
        date: "long",
        hour: "numeric",
        minute: "numeric",
      },
    }
  },

}
</script>

<style scoped>

.main-container {
  padding: 10px;
  display: flex;
  flex-direction: column;

}

.option-container {
  display: flex;
  justify-content: space-between;
}

.btn-container {
  display: flex;
  gap: 20px;
}

.events-container {
  margin-top: 20px;
}

.cell-action__buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>