import {
    deleteUser, fetchAdminList,
    fetchForms,
    fetchSupportMessages,
    fetchSeparateUsers, fetchUserScores,
    fetchUsersList,
    updateUserData, updateUserScores
} from "@/store/admin/serverInterationMethods";
import {errorHandler} from "@/lib/errorHandler";

export const admin = {
    actions: {
        async fetchUsersList({getters, commit}, {page, limit, to, from, sort, name, role}) {
            const {data} = await fetchUsersList(getters.token, page, limit, to, from, sort, name, role);
            commit('setUsersList', Array.isArray(data) ? data : []);
        },
        async fetchAdminList({getters, commit}, {page, limit}) {
            const {data} = await fetchAdminList(getters.token, page, limit);
            commit('setUsersList', data);
        },
        async fetchSeparateUsers({getters, commit}, id) {
            const {data} = await fetchSeparateUsers(getters.token, id);
            commit('setSeparateUser', data)
        },
        async updateUserScores({getters}, payload) {
            await updateUserScores(getters.token, payload);
        },
        async fetchUserScores({getters}, id) {
            const {data} = await fetchUserScores(getters.token, id);
            return data;
        },
        async changeUserStatus({getters}, status) {
            const user = getters.separateUser;
            user.status = status;
            await updateUserData(getters.token, user);
            if (status === 'chat_blocked') {
                this.dispatch('blockedUserChat', user.id);
            }
        },
        async updateUserData({getters}, user) {
            if (!user.password) {
                delete user.password;
            }

            if (user.sideData) {
                user.sideData = JSON.stringify(user.sideData);
            }

            const {status} = await updateUserData(getters.token, user);
            errorHandler({status, name: 'update'});
        },
        async deleteUser({getters}, id) {
            const {status} = await deleteUser(getters.token, id);
            errorHandler({status, name: 'delete'});
        },
        async fetchForms({getters, commit}, {page, limit, to, from, sort}) {
            const {data} = await fetchForms(getters.token, page, limit, to, from, sort);
            commit('setForms', {data: Array.isArray(data) ? data : [], page});
            return data
        },
        async fetchSupportMessages({ getters }, page) {
            const {data} = await fetchSupportMessages(getters.token, page);
            return data;
        },
    },
    mutations: {
        setUsersList(state, data) {
            state.usersList = data?.map(item => {
                if(item.sideData) {
                    item.sideData = JSON.parse(item.sideData);
                }
                return item;
            });
        },
        setSeparateUser(state, data) {
            state.separateUser = data;
            if (state.separateUser?.sideData && typeof state.separateUser.sideData === 'string') {
                state.separateUser.sideData = JSON.parse(state.separateUser.sideData);
            } else {
                state.separateUser.sideData = {};
            }
        },
        setForms(state, {data, page}) {
            if (page === 1) {
                state.forms = data;
            } else {
                state.forms = state.forms.concat(data);
            }
        },
        clearTableList(state) {
            state.usersList = [];
        }
    },
    state: {
        usersList: null,
        separateUser: null,
        forms: null
    },
    getters: {
        usersList: state => state.usersList,
        separateUser: state => state.separateUser,
        forms: state => state.forms
    }
}