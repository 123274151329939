<template>
  <a-modal :visible="viewChangePassword" title="Сменить пароль" @ok="updatePassword" @cancel="cancelPassword"
           ok-text="Подтвердить"
           cancel-text="Отменить">
    <a-input-password placeholder="Новый пароль" v-model:value.trim="newPassword"></a-input-password>
  </a-modal>

  <div class="container">
    <div class="container">

      <div class="usr-fields">
        <div class="usr-fields-el">
          <a-typography-text>ФИО:</a-typography-text>
          <a-input placeholder="ФИО" v-model:value="admin.name"></a-input>
        </div>

        <div class="usr-fields-el">
          <a-typography-text>Логин:</a-typography-text>
          <a-input placeholder="Email" v-model:value.trim="admin.login"></a-input>
        </div>

        <div class="usr-fields-el change-pass">
          <a-button type="primary" ghost @click="viewChangePassword=true">Сменить пароль</a-button>
        </div>


        <div class="edit-buttons ">
          <a-button type="primary" @click="saveAdminData">Сохранить изменения</a-button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  data() {
    return {
      admin: null,
      newPassword: null,

      viewChangePassword: false
    }
  },
  name: "SettingsPage",
  methods: {
    cancelPassword() {
      this.newPassword = null;
      this.viewChangePassword = false;
    },
    updatePassword() {
      if (this.newPassword)
        this.$store.dispatch('updateAdminData', {password: this.newPassword}).then(() => {
          this.viewChangePassword = false;
        });
    },
    saveAdminData() {
      let admin = {
        name: this.admin.name,
        login: this.admin.login
      }
      this.$store.dispatch('updateAdminData', admin);
    }
  },
  beforeMount() {
    this.admin = JSON.parse(JSON.stringify(this.user));
  }

}
</script>

<style scoped>
.usr-fields {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 30px;
}

.usr-fields-el {
  display: flex;
  width: 100%;
  max-width: 400px;
  flex-direction: column;
  gap: 5px
}

.edit-buttons {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}

.change-pass {
  max-width: 270px;
}


</style>