<template>

  <LoaderSpinner v-if="loader"></LoaderSpinner>
  <a-modal :visible="viewChangePassword" title="Сменить пароль" @ok="viewChangePassword=false" @cancel="cancelPassword"
           ok-text="Подтвердить"
           cancel-text="Отменить">
    <a-input-password placeholder="Новый пароль" v-model:value.trim="newPassword"></a-input-password>
  </a-modal>
  <div class="container" v-if="user">

    <div class="usr-fields">
      <div class="usr-fields-el" v-if="user?.role!=='exhibit'">
        <a-typography-text>ФИО:</a-typography-text>
        <a-input placeholder="ФИО" v-model:value="user.name"></a-input>
      </div>

      <div class="usr-fields-el" v-else>
        <a-typography-text>Название компании:</a-typography-text>
        <a-input placeholder="Название компании" v-model:value="user.name"></a-input>
      </div>

      <div class="usr-fields-el">
        <a-typography-text>Email:</a-typography-text>
        <a-input placeholder="Email" v-model:value="user.email"></a-input>
      </div>

      <div class="usr-fields-el">
        <a-typography-text>Номер телефона:</a-typography-text>
        <a-input ref="phone" placeholder="Номер телефона" v-model:value="user.phone"></a-input>
      </div>


      <div class="usr-fields-el" v-if="user?.role==='exhibit'">
        <a-typography-text>Сайт компании:</a-typography-text>
        <a-input ref="phone" placeholder="Сайт вашей компании" v-model:value="user.sideData['companySite']"></a-input>
      </div>


      <div class="usr-fields-el" v-if="user?.role==='exhibit'">
        <a-typography-text>Адрес:</a-typography-text>
        <a-input ref="phone" placeholder="Ваш адресс" v-model:value="user.sideData['companyAddress']"></a-input>
      </div>


      <div class="usr-fields-el" v-if="user?.role==='exhibit'">
        <a-typography-text>Видео-ссылка:</a-typography-text>
        <a-input ref="phone" placeholder="URL" v-model:value="user.sideData['videoLink']"></a-input>
      </div>

      <div class="usr-fields-el" v-if="user?.role==='exhibit'">
        <a-typography-text>VK:</a-typography-text>
        <a-input ref="phone" placeholder="URL" v-model:value="user.sideData['vkLink']"></a-input>
      </div>

      <div class="usr-fields-el" v-if="user?.role==='exhibit'">
        <a-typography-text>Youtube:</a-typography-text>
        <a-input ref="phone" placeholder="URL" v-model:value="user.sideData['youtubeLink']"></a-input>
      </div>

      <div class="usr-fields-el" v-if="user?.role==='exhibit'">
        <a-typography-text>Facebook:</a-typography-text>
        <a-input ref="phone" placeholder="URL" v-model:value="user.sideData['facebookLink']"></a-input>
      </div>

      <div class="usr-fields-el mw-100" v-if="user?.role==='exhibit'">
        <a-typography-text>Медиагалерея:</a-typography-text>
        <a-list :grid="{column:3,gutter:10}"
                :data-source="user.sideData['mediaGallery']?.split('|')?.filter(item=>item)">
          <template #renderItem="{ item }">
            <a-list-item>
              <a-card hoverable style="width: 300px">
                <template #cover>
                  <img
                      style="height: 300px"
                      alt="example"
                      :src="item"
                  />
                </template>
                <template #actions>
                  <a-button danger @click="deleteMediaGalleryFile(item)">
                    <template #icon>
                      <delete-outlined key="edit"/>
                    </template>
                  </a-button>

                </template>
              </a-card>
            </a-list-item>
          </template>
        </a-list>
        <a-button @click="$refs.mediaGallery?.click()" style="width: 150px">
          Добавить
          <input type="file" ref="mediaGallery" hidden="hidden" accept="image/*" @change="uploadMediaGalleryFile"/>
        </a-button>
      </div>


      <div class="usr-fields-el">
        <a-button ghost type="primary" style="width: 200px" @click="viewChangePassword=true">Сменить пароль</a-button>
      </div>

      <div class="usr-fields-el">
        <a-typography-text>Дата регистрации:</a-typography-text>
        <a-typography-text strong>{{
            new Date(user.registerDate).toLocaleDateString('ru-RU', dateOptionsFull)
          }}
        </a-typography-text>
      </div>

      <div class="usr-fields-el el-short">
        <a-typography-text>Роль:</a-typography-text>
        <a-select v-model:value="user.role" placeholder="Роль" style="width: 150px">
          <a-select-option value="user">Пользователь</a-select-option>
          <a-select-option value="guide">Экскурсовод</a-select-option>
          <a-select-option value="lecture">Ведущий</a-select-option>
          <a-select-option value="exhibit">Экспонат</a-select-option>
        </a-select>
      </div>
      <div class="usr-fields-el el-short">
        <a-checkbox v-model:checked="user.vip">VIP пользователь</a-checkbox>
      </div>
      <div class="edit-buttons" v-if="user.status!=='deleted'">
        <a-button type="primary" @click="saveUserData">Сохранить изменения</a-button>
        <div class="" style="display: flex;flex-direction: column;gap: 8px">
          <a-button v-if="user.status==='unapproved'" type="primary" @click="changeUserStatus('available')">Подтвердить
          </a-button>
          <a-button v-if="user.status!=='unapproved'" type="primary"
                    @click="changeUserStatus(separateUser.status==='banned'?'available':'banned')"
                    :danger="separateUser.status !== 'banned'" ghost>
            {{ separateUser.status === 'banned' ? 'Разблокировать' : 'Заблокировать' }}
          </a-button>
          <a-button v-if="user.status!=='unapproved'" type="primary"
                    @click="changeUserStatus(separateUser.status==='chat_blocked'?'available':'chat_blocked')"
                    :danger="separateUser.status !== 'chat_blocked'"
                    ghost> {{ separateUser.status === 'chat_blocked' ? 'Разблокировать' : 'Заблокировать' }} чат
          </a-button>
          <a-button type="primary" @click="$store.dispatch('deleteUser',id)" danger>Удалить</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {dateOptionsFull} from "@/lib/convertTime";
import {DeleteOutlined} from "@ant-design/icons-vue";
import LoaderSpinner from "@/components/main/LoaderSpinner.vue";

export default {
  name: "SeparateUserPage",
  components: {LoaderSpinner, DeleteOutlined},
  props: ['id'],
  computed: {
    separateUser() {
      return this.$store.getters.separateUser;
    }
  },
  data() {
    return {
      user: null,
      dateOptionsFull,
      mask: "+0(000)000-00-00",
      emailReg: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,

      newPassword: null,

      viewChangePassword: false,

      mediaGalleryFiles: [],

      loader: false,


    }
  },
  methods: {
    deleteMediaGalleryFile(url) {
      this.user.sideData.mediaGallery = this.user.sideData.mediaGallery.replace(`${url}`, '');
      const fileIndex = this.mediaGalleryFiles?.findIndex((val) => {
        return val?.url === url
      });
      if (fileIndex > -1) {
        this.mediaGalleryFiles.splice(fileIndex, 1);
      }
    },

    uploadMediaGalleryFile(event) {
      const file = event?.target?.files[event?.target?.files.length - 1];
      const url = URL.createObjectURL(file);
      this.mediaGalleryFiles.push({
        file,
        url
      });
      this.user.sideData['mediaGallery'] = (this.user.sideData.mediaGallery ? `${this.user.sideData.mediaGallery}|${url}` : url)
    },


    async saveUserData() {
      this.loader = true;

      for (let i = 0; i < this.mediaGalleryFiles?.length; i++) {
        await this.$store.dispatch('uploadFile', this.mediaGalleryFiles[i].file).then((url) => {
          this.user.sideData.mediaGallery = this.user.sideData.mediaGallery?.replace(this.mediaGalleryFiles[i].url, url);
        });
      }

      this.$store.dispatch('updateUserData', {
        avatarUrl: this.user.avatarUrl,
        email: this.user.email,
        id: this.user.id,
        name: this.user.name,
        phone: this.user.phone,
        registerDate: this.user.registerDate,
        role: this.user.role,
        password: this.newPassword,
        sideData: this.user.sideData,
        status: this.user.status,
        vip: this.user.vip
      }).then(() => {
        this.fetchSeparateUser();
      })
    },
    changeUserStatus(status) {
      this.loader = true;
      this.$store.dispatch('changeUserStatus', status).then(this.fetchSeparateUser);
    },
    cancelPassword() {
      this.newPassword = null;
      this.viewChangePassword = false;
    },
    fetchSeparateUser() {
      this.$store.dispatch('fetchSeparateUsers', this.id).then(() => {
        this.user = this.separateUser;
        this.mediaGalleryFiles = [];
        this.loader = false;
      });
    },
  },
  beforeMount() {
    this.loader = true;
    this.$store.commit('createSocket');
    this.fetchSeparateUser();
  },
}
</script>

<style scoped>


.usr-fields {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 30px;
}

.usr-fields-el {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  max-width: 500px;
}

.edit-buttons {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-short {
  max-width: 150px;
}

.mw-100 {
  max-width: 100%;
}

</style>