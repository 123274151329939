import {appConfig} from "@/lib/appConfig";

export const socket = {
    actions: {
        async fetchMessages({getters}) {
            await getters.socket.send(JSON.stringify({
                action: 'GET_MESSAGES',
                page: getters.page,
                limit: getters.limit,
                token: getters.token
            }))
        },
        async blockedUserChat({getters}, userId) {
            await getters.socket?.send(JSON.stringify({
                action: 'BLOCK_USER',
                userId,
                token: getters.token
            }))
        },
    },
    mutations: {
        createSocket(state) {
            let ret = true;
            if (ret) return;
            if (state.socket) {
                return;
            }
            state.socket = new WebSocket(appConfig.socket);
            state.socket.onopen = () => {
                console.log("Connected");
                try {
                    document.dispatchEvent(new Event('scrollMessages'));
                } catch (e) {
                    console.log(e);
                }
                //this.scrollMessage()
            };
            state.socket.onmessage = (message) => {
                try {
                    let data = JSON.parse(message.data);
                    if (data.action === "WRITE_NEW_MESSAGE" || data.action === "MESSAGES") {
                        data = (data.action === "WRITE_NEW_MESSAGE") ? data.msg : data.messages;
                        if (data.length == null) {
                            state.messages.push(data);
                        } else {
                            if (state.page === 1) {
                                state.messages = data.reverse();

                            } else {
                                state.messages = data.reverse().concat(state.messages);
                            }
                            if (data.length < state.limit) {
                                state.pagination = false;
                            }
                        }
                        if (!state.pageUp) {
                            let dialog = document.getElementById('dialog');
                            if (dialog && dialog.scrollHeight - dialog.scrollTop < 450) {
                                try {
                                    document.dispatchEvent(new Event('scrollMessages'));
                                } catch (e) {
                                    console.log(e);
                                }
                            }
                        } else {
                            state.pageUp = false;
                        }


                    }
                    if (data.action === 'USER_BLOCKED') {
                        this.commit('deleteMessagesByBlockedUser', data.userId);
                    }
                } catch (e) {
                    console.log(e);
                }
            };
        },
        closeSocket(state) {
            try {
                state.socket.close();
            } catch (e) {
                console.log(e)
            }
            state.socket = null;

        },
        loadMoreMessages(state) {
            state.page++;
            state.pageUp = true;
            this.dispatch('fetchMessages');
        },
        deleteMessagesByBlockedUser(state, userId) {
            state.messages = state.messages.filter(item => item.userId !== userId);
        },
    },
    state: {
        messages: [],
        socket: null,
        page: 1,
        limit: 20,
        pageUp: false,
        pagination: true
    },
    getters: {
        messages: state => state.messages,
        socket: state => state.socket,
        page: state => state.page,
        limit: state => state.limit,
        pagination: state => state.pagination,
    }
}