<template>
  <div class="cells-container">

    <div class="cells-content">
      <div v-for="x in xLength" :key="x" class="column">
        <div class="" v-for="y in yLength" :key="y">

          <a-button
              v-if="cellStatus({x,y})==='danger'"
              style="width: 10px"
              @click="pickCell({x,y})" :type="cellStatus({x,y})">
            <p></p>
            <template #icon>
              <ArrowUpOutlined
                  v-if="currentSlotInfo?.rotation ===270&&currentSlotInfo?.yMin===y &&  currentSlotInfo?.xList?.includes(x)"/>
              <ArrowDownOutlined
                  v-if="currentSlotInfo?.rotation === 90 &&currentSlotInfo?.yMax===y &&  currentSlotInfo?.xList?.includes(x)"/>


              <ArrowLeftOutlined
                  v-if="currentSlotInfo?.rotation ===180 &&currentSlotInfo?.xMin===x &&  currentSlotInfo?.yList?.includes(y)"/>
              <ArrowRightOutlined
                  v-if="currentSlotInfo?.rotation ===0 &&currentSlotInfo?.xMax===x &&  currentSlotInfo?.yList?.includes(y)"/>
            </template>

          </a-button>


          <a-popover trigger="click"
                     v-else>
            <template #content>
              <div class="cells-editor" v-if="currentSlotInfo">
                <a-button :danger="!(y===currentSlotInfo?.yMin&& y!==1)"
                          @click="changeCellsState('up',{x,y,reverse:!(y===currentSlotInfo?.yMin&& y!==1)})">
                  <CaretUpOutlined v-if="y===currentSlotInfo?.yMin&& y!==1"/>
                  <VerticalAlignBottomOutlined v-else/>
                </a-button>

                <a-button :danger="!(y===currentSlotInfo?.yMax&& y!==yLength)"
                          @click="changeCellsState('down',{x,y,reverse:!(y===currentSlotInfo?.yMax&& y!==yLength)})">
                  <CaretDownOutlined v-if="y===currentSlotInfo?.yMax&& y!==yLength"/>
                  <VerticalAlignTopOutlined v-else/>
                </a-button>

                <a-button :danger="!(x===currentSlotInfo?.xMin&& x!==1)"
                          @click="changeCellsState('left',{x,y,reverse:!(x===currentSlotInfo?.xMin&& x!==1)})">
                  <CaretLeftOutlined v-if="x===currentSlotInfo?.xMin&& x!==1"/>
                  <VerticalRightOutlined v-else/>
                </a-button>

                <a-button :danger="!(x===currentSlotInfo?.xMax&& x!==xLength)"
                          @click="changeCellsState('right',{x,y,reverse:!(x===currentSlotInfo?.xMax&& x!==xLength)})">
                  <CaretRightOutlined v-if="x===currentSlotInfo?.xMax&& x!==xLength"/>
                  <VerticalLeftOutlined v-else/>

                </a-button>

                <a-button @click="changeCellsState('rotation')">
                  <RotateRightOutlined/>
                </a-button>

                <a-button danger @click="changeCellsState('delete')">
                  <DeleteOutlined/>
                </a-button>
              </div>
            </template>
            <a-button
                style="width: 10px"
                @click="pickCell({x,y})" :type="cellStatus({x,y})">
              <p></p>
              <template #icon>
                <ArrowUpOutlined
                    v-if="currentSlotInfo?.rotation ===270&&currentSlotInfo?.yMin===y &&  currentSlotInfo?.xList?.includes(x)"/>
                <ArrowDownOutlined
                    v-if="currentSlotInfo?.rotation ===90 &&currentSlotInfo?.yMax===y &&  currentSlotInfo?.xList?.includes(x)"/>


                <ArrowLeftOutlined
                    v-if="currentSlotInfo?.rotation ===180 &&currentSlotInfo?.xMin===x &&  currentSlotInfo?.yList?.includes(y)"/>
                <ArrowRightOutlined
                    v-if="currentSlotInfo?.rotation ===0 &&currentSlotInfo?.xMax===x &&  currentSlotInfo?.yList?.includes(y)"/>
              </template>

            </a-button>
          </a-popover>

        </div>

      </div>
    </div>
  </div>
</template>


<script>
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CaretUpOutlined,
  CaretLeftOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
  RotateRightOutlined,
  DeleteOutlined,
  VerticalLeftOutlined,
  VerticalRightOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined

} from "@ant-design/icons-vue";

export default {
  props: ['currentUser', 'eventId', 'allSlots'],
  name: "EventExhibitions",
  components: {
    ArrowUpOutlined,
    ArrowDownOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    CaretUpOutlined,
    CaretLeftOutlined,
    CaretDownOutlined,
    CaretRightOutlined,
    RotateRightOutlined,
    DeleteOutlined,
    VerticalLeftOutlined,
    VerticalRightOutlined,
    VerticalAlignBottomOutlined,
    VerticalAlignTopOutlined

  },
  data() {
    return {
      cellsTaken: [],
      yLength: 30,
      xLength: 30,
    }
  },
  computed: {
    currentSlotInfo() {

      const currentSlotIndex = this.cellsTaken?.findIndex(item => item?.clientId === this.currentUser?.id);

      if (currentSlotIndex > -1 && Array.isArray(this.cellsTaken[currentSlotIndex]?.order)) {


        const xList = this.cellsTaken[currentSlotIndex]?.order?.map(item => item?.x)
        const yList = this.cellsTaken[currentSlotIndex]?.order?.map(item => item?.y)


        return {
          currentSlotIndex,

          xMax: xList?.length ? Math.max(...xList) : 0,
          xMin: xList?.length ? Math.min(...xList) : 0,
          yMax: xList?.length ? Math.max(...yList) : 0,
          yMin: xList?.length ? Math.min(...yList) : 0,
          rotation: this.cellsTaken[currentSlotIndex]?.rotation ? this.cellsTaken[currentSlotIndex]?.rotation : 0,

          xList: Array.from(new Set(xList)),
          yList: Array.from(new Set(yList)),
        }
      }

      return null;

    }
  },
  methods: {
    changeCellsState(directory, params) {


      if (directory === 'up') {


        if (params?.reverse) {
          const yMin = this.currentSlotInfo?.yMax + 1;
          this.cellsTaken[this.currentSlotInfo?.currentSlotIndex].order = this.cellsTaken[this.currentSlotInfo?.currentSlotIndex].order?.filter(item => item.y >= yMin);
          return;
        }


        const yMin = this.currentSlotInfo?.yMin - 1;

        if (yMin >= 1) {

          if (this.currentSlotInfo?.xList.map(x => this.cellStatus({x, y: yMin})).includes('danger')) return;

          for (let i = 0; i < this.currentSlotInfo?.xList?.length; i++) {
            this.cellsTaken[this.currentSlotInfo?.currentSlotIndex].order?.push(
                {
                  x: this.currentSlotInfo?.xList[i],
                  y: yMin
                }
            )
          }
        }
      }

      if (directory === 'down') {

        if (params?.reverse) {
          const yMax = this.currentSlotInfo?.yMax - 1;
          this.cellsTaken[this.currentSlotInfo?.currentSlotIndex].order = this.cellsTaken[this.currentSlotInfo?.currentSlotIndex].order?.filter(item => item.y <= yMax);
          return;
        }


        const yMax = this.currentSlotInfo?.yMax + 1
        if (yMax <= this.yLength) {

          if (this.currentSlotInfo?.xList.map(x => this.cellStatus({x, y: yMax})).includes('danger')) return;

          for (let i = 0; i < this.currentSlotInfo?.xList?.length; i++) {
            this.cellsTaken[this.currentSlotInfo?.currentSlotIndex].order?.push(
                {
                  x: this.currentSlotInfo?.xList[i],
                  y: yMax
                }
            )
          }
        }
      }
      if (directory === 'left') {

        if (params?.reverse) {

          const xMin = this.currentSlotInfo?.xMin + 1;
          this.cellsTaken[this.currentSlotInfo?.currentSlotIndex].order = this.cellsTaken[this.currentSlotInfo?.currentSlotIndex].order?.filter(item => item.x >= xMin);
          return;
        }


        const xMin = this.currentSlotInfo?.xMin - 1;
        if (xMin >= 1) {

          if (this.currentSlotInfo?.yList.map(y => this.cellStatus({x: xMin, y})).includes('danger')) return;


          for (let i = 0; i < this.currentSlotInfo?.yList?.length; i++) {
            this.cellsTaken[this.currentSlotInfo?.currentSlotIndex].order?.push(
                {
                  x: xMin,
                  y: this.currentSlotInfo?.yList[i]
                }
            )
          }
        }
      }
      if (directory === 'right') {

        if (params?.reverse && params?.x && params?.y) {
          const xMax = this.currentSlotInfo?.xMax - 1;
          this.cellsTaken[this.currentSlotInfo?.currentSlotIndex].order = this.cellsTaken[this.currentSlotInfo?.currentSlotIndex].order?.filter(item => item.x <= xMax);
          return;
        }

        const xMax = this.currentSlotInfo?.xMax + 1;
        if (xMax <= this.xLength) {

          if (this.currentSlotInfo?.yList.map(y => this.cellStatus({x: xMax, y})).includes('danger')) return;

          for (let i = 0; i < this.currentSlotInfo?.yList?.length; i++) {
            this.cellsTaken[this.currentSlotInfo?.currentSlotIndex].order?.push(
                {
                  x: xMax,
                  y: this.currentSlotInfo?.yList[i]
                }
            )
          }
        }
      }
      if (directory === 'delete') {
        this.cellsTaken[this.currentSlotInfo?.currentSlotIndex].order.length = 0;
        return;
      }
      if (directory === 'rotation') {
        this.cellsTaken[this.currentSlotInfo?.currentSlotIndex].rotation += 90;
        if (this.cellsTaken[this.currentSlotInfo?.currentSlotIndex].rotation === 360) {
          this.cellsTaken[this.currentSlotInfo?.currentSlotIndex].rotation = 0;
        }
      }
    },


    cellStatus({x, y}) {
      for (let i = 0; i < this.cellsTaken?.length; i++) {
        for (let j = 0; j < this.cellsTaken[i]?.order?.length; j++) {
          if (this.cellsTaken[i]?.order[j]?.x === x && this.cellsTaken[i]?.order[j]?.y === y) {
            return (this.cellsTaken[i]?.clientId === this.currentUser?.id ? 'primary' : 'danger');
          }
        }
      }

      return 'ghost';

    },

    saveCells() {
      const saveCells = [...this.cellsTaken];

      for (let i = 0; i < saveCells?.length; i++) {
        if (saveCells[i]?.order?.length) {
          const xList = saveCells[i]?.order?.map(item => item?.x)
          const yList = saveCells[i]?.order?.map(item => item?.y)

          const xMax = Math.max(...xList) - 1;
          const xMin = Math.min(...xList) - 1;

          const yMax = Math.max(...yList) - 1;

          const yMin = Math.min(...yList) - 1;

          const width = xMax - xMin;
          const height = yMax - yMin;

          const xCenter = (xMax + xMin) / 2;
          const yCenter = (yMax + yMin) / 2;


          saveCells[i]['pavilionIndexes'] = `{${width};${height};${xCenter};${yCenter};${saveCells[i]?.rotation ? saveCells[i]?.rotation : 0}}`

        }

        saveCells[i].order = JSON.stringify(saveCells[i].order);

        delete saveCells[i].rotation;

      }


      this.$store.dispatch('saveExhibits', saveCells);
    },

    pickCell({x, y}) {

      if (this.cellStatus({x, y}) === "danger") return;

      if (this.currentSlotInfo?.currentSlotIndex > -1 && !this.cellsTaken[this.currentSlotInfo?.currentSlotIndex]?.order?.length) {

        const elIndex = this.cellsTaken[this.currentSlotInfo?.currentSlotIndex]?.order?.findIndex((item) => item?.x === x && item?.y === y);

        if (elIndex > -1) return;


        this.cellsTaken[this.currentSlotInfo?.currentSlotIndex]?.order?.push({x, y});

      }


    },

    parseCells() {

      this.cellsTaken = JSON.parse(JSON.stringify(this.allSlots))?.map(item => {
        try {


          if (!item.order) {
            item.order = [];
            item['rotation'] = 0;

            return item;
          }


          if (!Array.isArray(item.order)) {
            item.order = JSON.parse(item?.order);
          }


          if (item?.pavilionIndexes) {
            item['rotation'] = Number(item?.pavilionIndexes.replace(/[{}]/g, '')?.split(';')?.reverse()[0]);
          } else {
            item['rotation'] = 0;
          }


        } catch (e) {
          item.order = [];
          item['rotation'] = 0;
        }

        return item;
      })
    },
  },

  beforeMount() {
    this.parseCells();
  }

}
</script>


<style scoped>
.cells-container {
  margin: 15px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;

}

.cells-content {
  display: grid;
  grid-template-columns: repeat(30, 1fr);
  gap: 10px;
}

.cells-editor {
  display: flex;
  gap: 10px;
}

.column {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
}

.column :deep(.ant-btn) {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  max-width: 100%;
  width: 100%;
}

.column :deep(.ant-btn span) {
  margin-left: 0;
}

.column :deep(.anticon-arrow-right) {
  position: absolute;
  left: 2px;
}

</style>