// makes post request to server
export async function makePostReq(url, data, params) {
    const requestOptions = {
        method: "POST",
        body: data
    };
    if (params) {
        requestOptions['headers'] = {};
        if (params.token) {
            requestOptions.headers['X-Auth-Token'] = params.token;
        }
        if (params.data) {
            requestOptions.headers['Content-Type'] = 'application/json;charset=UTF-8';
            requestOptions.body = JSON.stringify(data);
        }
    }


    try {
        const req = await fetch(url, requestOptions);
        const reqData = await req.json();
        return {
            data: reqData,
            status: req.status
        }

    } catch (e) {
        return {
            data: null,
            status: 500
        }
    }

}

export async function makePutReq(url, data, params) {
    const requestOptions = {
        method: "PATCH",
        body: data
    };
    if (params) {
        requestOptions['headers'] = {};
        if (params.token) {
            requestOptions.headers['X-Auth-Token'] = params.token;
        }
        if (params.data) {
            requestOptions.headers['Content-Type'] = 'application/json;charset=UTF-8';
            requestOptions.body = JSON.stringify(data);
        }
    }

    try {
        const req = await fetch(url, requestOptions);
        const reqData = await req.json();
        return {
            data: reqData,
            status: req.status
        }
    } catch (e) {
        return {
            data: null,
            status: 500
        }
    }

}

export async function makeDeleteReq(url, data, params) {
    const requestOptions = {
        method: "DELETE",
        body: data
    };
    if (params) {
        requestOptions['headers'] = {};
        if (params.token) {
            requestOptions.headers['X-Auth-Token'] = params.token;
        }
        if (params.data) {
            requestOptions.headers['Content-Type'] = 'application/json;charset=UTF-8';
            requestOptions.body = JSON.stringify(data);
        }
    }

    try {
        const req = await fetch(url, requestOptions);
        const reqData = await req.json();
        return {
            data: reqData,
            status: req.status
        }
    } catch (e) {
        return {
            data: null,
            status: 500
        }
    }

}

export async function makeGetReq(url, params) {
    const requestOptions = {
        method: "GET"
    };
    if (params) {
        requestOptions['headers'] = {};
        if (params.token) {
            requestOptions.headers['X-Auth-Token'] = params.token;
        }
    }



    try {

        const req = await fetch(url, requestOptions);
        let reqData;
        if (params.isFile) {
            reqData = await req.arrayBuffer();
        } else if (params.isBlob) {
            reqData = await req.blob();
        } else {
            reqData = await req.json();
        }
        return {
            data: reqData,
            status: req.status
        }
    } catch (e) {
        return {
            data: null,
            status: 500
        }
    }

}