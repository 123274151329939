<template>
  <a-modal @cancel="closeForm" :ok-button-props="{hidden:'hidden'}" :cancel-button-props="{hidden:'hidden'}"
           :visible="viewForm" v-if="viewForm&&currentForm"
           title="Обращение">
    <div class="modal-list">
      <div class="modal-el">
        <a-typography-text strong>Тема обращения:</a-typography-text>
        <a-typography-text>{{ currentForm.category }}</a-typography-text>
      </div>
      <div class="modal-el">
        <a-typography-text strong>Имя:</a-typography-text>
        <a-typography-text>{{ currentForm.name }}</a-typography-text>
      </div>
      <div class="modal-el">
        <a-typography-text strong>Email:</a-typography-text>
        <a-typography-text>{{ currentForm.email }}</a-typography-text>
      </div>
      <div class="modal-el">
        <a-typography-text strong>Номер телефона:</a-typography-text>
        <a-typography-text>{{ currentForm.phone }}</a-typography-text>
      </div>
      <div class="modal-el">
        <a-typography-text strong>Сообщение:</a-typography-text>
        <a-typography-text>{{ currentForm.message }}</a-typography-text>
      </div>
    </div>
  </a-modal>
  <div class="container" v-if="forms">
    <div class="buttons-container">
      <a-button type="primary" ghost @click="$store.dispatch('downloadCSV')">Выгрузить таблицу

      </a-button>
    </div>
    <a-table :data-source="forms" :columns="columns" :custom-row="customRow">
      <template #bodyCell="{column,record}">
        <div class="" v-if="column.key==='createDate'">
          {{ record.createDate ? new Date(record.createDate).toLocaleDateString('ru-RU', dateOptions) : 'Нет даты' }}
        </div>
      </template>
      <template #customFilterDropdown="{column,confirm}">
        <div class="filters-container" v-if="column.key==='createDate'">
          <div class="filters-el">
            <h4>От: </h4>
            <a-date-picker :locale="locale" v-model:value="from"/>
          </div>
          <div class="filters-el">
            <h4>До: </h4>
            <a-date-picker :locale="locale" v-model:value="to"/>
          </div>
          <div class="filters-el">
            <h4>Сортировка по датам: </h4>
            <a-select placeholder="Сортировка" style="width: 160px" v-model:value="sort">
              <a-select-option value="dec">
                Сначала новые
              </a-select-option>
              <a-select-option value="inc">
                Сначала старые
              </a-select-option>
            </a-select>
          </div>

          <div class="filters-buttons">
            <a-button
                @click="fetchForms(confirm)"
                type="primary">
              Найти
            </a-button>
            <a-button @click="dropFormsFilters(confirm)">
              Сбросить
            </a-button>
          </div>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/ru_RU"
import dayjs from "dayjs";
import 'dayjs/locale/ru';

export default {
  name: "FormsPage",
  data() {
    return {
      locale,
      dayjs,

      page: 1,
      limit: 100,
      pagination: true,

      currentForm: null,
      viewForm: false,
      dateOptions: {
        month: "long",
        day: "numeric",
        year: "numeric"
      },


      columns: [
        {
          title: 'Тема обращения',
          key: 'category',
          dataIndex: 'category'
        },
        {
          title: 'ФИО',
          key: 'name',
          dataIndex: 'name'
        },
        {
          title: 'Email',
          key: 'email',
          dataIndex: 'email'
        },
        {
          title: 'Дата создания',
          key: 'createDate',
          dataIndex: 'createDate',
          customFilterDropdown: true
        },

      ],
      customRow: (record) => {
        return {
          style: {
            cursor: 'pointer'
          },
          onclick: () => this.openForm(record)
        }
      },

      to: '',
      from: '',
      sort: 'dec'

    }
  },
  methods: {
    openForm(form) {
      this.currentForm = form;
      this.viewForm = true;
    },
    closeForm() {
      this.currentForm = null;
      this.viewForm = false;
    },
    fetchForms(confirm) {
      this.$store.dispatch('fetchForms', {
        page: this.page,
        limit: this.limit,
        to: this.to ? this.dayjs(this.to).toDate().toUTCString() : '',
        from: this.from ? this.dayjs(this.from).toDate().toUTCString() : '',
        sort: this.sort
      }).then((data) => {
        if (confirm) {
          this.page = 1
          this.pagination = true;
          confirm();
        }
        if (data && data.length < this.limit) {
          this.pagination = false;
        }
      });
    },
    dropFormsFilters(confirm) {
      this.to = '';
      this.from = '';
      this.page = 1;
      this.sort = 'dec';
      this.fetchForms(confirm);
    }
  },
  computed: {
    forms() {
      return this.$store.getters.forms;
    }
  },
  beforeMount() {
    this.dayjs.locale('ru');
    this.fetchForms();
  },
}
</script>

<style scoped>

.modal-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.modal-el {
  display: flex;
  flex-direction: column;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}


.filters-container {
  padding: 10px 10px 0 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filters-el {
  width: 170px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}


.filters-buttons {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  margin: 10px 0;
}

</style>