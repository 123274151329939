import store from '@/store';


// check if user has token - authorized
export const canEnterToUserPortal = async (callback) => {
    await store.dispatch('loadToken');
    if (store.getters.token) {
        await store.dispatch('fetchUserData');
    }
    callback(store.getters.token)
};