import {createRouter, createWebHistory} from 'vue-router';
import AuthorizationPage from "@/pages/AuthorizationPage";
import PersonalCabinetPage from "@/pages/personal/PersonalCabinetPage";
import SettingsPage from "@/pages/personal/SettingsPage";
import {canEnterToUserPortal} from "@/router/helpers";
import UsersListPage from "@/pages/users/UsersListPage";
import SeparateUserPage from "@/pages/users/SeparateUserPage";
import FormsPage from "@/pages/personal/FormsPage";
import UsersChatPage from "@/pages/users/UsersChatPage";
import EventsListPage from "@/pages/events/EventsListPage";
import EventEditorPage from "@/pages/events/EventEditorPage";
import NotFoundPage from "@/pages/NotFoundPage.vue";


// creating routes to all pages
const routes = [
    {
        path: '/',
        name: 'authorization',
        component: AuthorizationPage,
        beforeEnter: async (to, from, next) => {
            await canEnterToUserPortal(async (token) => {
                if (token) {
                    next({name: 'users'});
                } else {
                    next();
                }
            });
        }
    },
    {
        path: '/cabinet',
        name: 'personalCabinet',
        component: PersonalCabinetPage,
        children: [
            {
                path: 'settings',
                name: 'settings',
                component: SettingsPage
            },
            {
                path: 'users',
                name: 'users',
                component: UsersListPage
            },
            {
                path: 'users/:id',
                name: 'separateUser',
                component: SeparateUserPage,
                props: true
            },
            {
                path: 'forms',
                name: 'forms',
                component: FormsPage
            },
            {
                path: 'support',
                name: 'support',
                component: () => import("@/pages/personal/SupportPage.vue"),
            },
            {
                path: 'chats',
                name: 'chats',
                component: UsersChatPage
            },
            {
                path: 'events',
                name: 'eventsList',
                component: EventsListPage
            },
            {
                path: 'event/:id',
                name: 'eventEditor',
                props: true,
                component: EventEditorPage
            },
            {
                path: 'event/slots/:id',
                name: 'slotsSettings',
                props: true,
                component: () => import("@/pages/events/EventSlotsPageEditor.vue"),
            }


        ],
        beforeEnter: async (to, from, next) => {
            await canEnterToUserPortal( (token) => {
                if (token) {
                    next();
                } else {
                    next({name: 'authorization'});
                }
            });
        }
    },
    {
        path: "/:pathMatch(.*)*",
        component: NotFoundPage
    }
];

// creating Vue-Router instance with normal history mode
const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;