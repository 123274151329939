import {useToast} from "vue-toastification";
import router from "@/router";
import store from "@/store";
//import store from "@/store";

const toast = useToast();

const notification = {
    params: {
        position: "top-center",
        timeout: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false
    },
    successNotification(app, msg) {
        toast.success(msg, this.params);
    },
    errorNotification(app, msg) {
        toast.error(msg, this.params);
    },
    warningNotification(app, msg) {
        toast.warning(msg, this.params);
    },
    infoNotification(app, msg) {
        toast.info(msg, this.params);
    }
};

export function errorHandler(event) {
    if (event?.status === 401 || event?.status === 403) {
        if (event?.data?.token === 'invalid') {
            notification.infoNotification(this, 'Требуется авторизация');
            store.dispatch('logout').then(function () {
                router.push({name: 'authorization'});
            });
            return;
        }

    }
    if (event.status === 500) {
        notification.errorNotification(this, 'Error');
        return;
    }

    switch (event.name) {
        case 'removeEvent': 
            notification[event.status === 200 ? 'successNotification' : 'errorNotification'](this, event.msg);
            break;
        case 'slotsSettings':
            notification.successNotification(this, 'Успешно');
            router.push({ name: 'slotsSettings', params: {id: event.id} });
            break;

        case 'saveExhibits':
            switch (event.status) {
                case 200:
                    notification.successNotification(this, 'Успешно');
                    break;
                default:
                    notification.errorNotification(this, 'Error');
            }
            break;

        case 'login':
            switch (event.status) {
                case 200:
                    notification.successNotification(this, 'Успешно');
                    router.push({name: 'users'})
                    break;
                case 401:
                    if (event.meta && event.meta.data) {
                        if (event.meta.data.not_found && event.meta.data.not_found === 'login') {
                            notification.infoNotification(this, 'Такого аккаунта не найдено');
                        }
                        if (event.meta.data.invalid && event.meta.data.invalid === 'password') {
                            notification.infoNotification(this, 'Неверный пароль');
                        }
                    }
                    break;
                default:
                    notification.errorNotification(this, 'Error');
            }
            break;


        case 'update':
            switch (event.status) {
                case 200:
                    notification.successNotification(this, 'Успешно обновлено');
                    break;

                case 401:
                    if (event.meta && event.meta.data && event.meta.data.not_unique && event.meta.data.not_unique === 'login') {
                        notification.infoNotification(this, 'Аккаунт с таким логином уже существует');
                    }
                    break;
                default:
                    notification.errorNotification(this, 'Error');
            }
            break;
        case 'create':
            switch (event.status) {
                case 200:
                    notification.successNotification(this, 'Успешно создано');
                    if (event.relocate) {
                        router.push({name: event.relocate});
                    }
                    break;
                case 201:
                    notification.successNotification(this, 'Успешно создано');
                    if (event.relocate) {
                        router.push({name: event.relocate});
                    }
                    break;
                case 401:
                    if (event.meta) {
                        if (event.meta.data) {
                            if (event.meta.data.not_unique) {
                                if (event.meta.data.not_unique === 'phone') {
                                    notification.infoNotification(this, 'Аккаунт с таким номером уже существует');
                                }
                                if (event.meta.data.not_unique === 'email') {
                                    notification.infoNotification(this, 'Аккаунт с таким email уже существует');
                                }
                            }
                        }
                    }
                    break
                default:
                    notification.errorNotification(this, 'Error');
            }
            break;

        case 'delete':
            switch (event.status) {
                case 200:
                    notification.infoNotification(this, 'Удалено');
                    router.go(-1);
                    break;
                default:
                    notification.errorNotification(this, 'Error');
            }
            break;

        case 'validation':

            if (event.meta.name === 'name') {
                notification.infoNotification(this, 'Введите ФИО');
                break;
            }
            if (event.meta.name === 'login') {
                notification.infoNotification(this, 'Введите логин');
                break;
            }
            if (event.meta.name === 'password') {
                notification.infoNotification(this, 'Некорректный пароль');
                break;
            }
            if (event.meta.name === 'email') {
                notification.infoNotification(this, 'Некорректный email');
                break;
            }
            if (event.meta.name === 'phone') {
                notification.infoNotification(this, 'Некорректный номер телефона');
                break;
            }
            break;


        case 'file':
            switch (event.status) {
                case 400:
                    notification.infoNotification(this, 'Файл не должен превышать 40мб');
                    break;
                default:
                    notification.errorNotification(this, 'Ошибка');
            }
            break;


        case 'logout':
            router.push({name: 'authorization'})
            break
    }
}