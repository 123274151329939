import {uploadFile} from "@/store/application/serverInterationMethods";


export const application = {
    state: {
        iterationInfo: null
    },
    mutations: {
        setIterationInfo(store, data) {
            store.iterationInfo = data;
        },
    },
    actions: {

        async uploadFile(_, file) {
            const formData = await new FormData();
            formData.append('file', file);
            const {data} = await uploadFile(formData);
            return data.url;
        },
    },
    getters: {
        iterationInfo: (state) => state.iterationInfo
    }
};