import {defaultHttpResHandler} from "@/store/helpers/defaultHttpResHandler";
import {makeDeleteReq, makeGetReq, makePostReq} from "@/store/helpers/request";
import {appConfig} from "@/lib/appConfig";

export const fetchUsersList = async (token, page, limit, to, from, sort, name, role) => await defaultHttpResHandler(async () => await makeGetReq(appConfig.domain + `/admin/client?page=${page}&limit=${limit}&to=${to ? to : ''}&from=${from ? from : ''}&sort=${sort ? sort : 'dec'}&name=${name ? name : ''}&role=${role ? role : ''}`, {token}));

export const fetchAdminList = async (token, page, limit) => await defaultHttpResHandler(async () => await makeGetReq(appConfig.domain + '/admin/all?page=' + page + '&limit=' + limit, {token}));


export const fetchSeparateUsers = async (token, id) => await defaultHttpResHandler(async () => await makeGetReq(appConfig.domain + '/admin/client/' + id, {token}));

export const fetchUserScores = async (token, id) => await defaultHttpResHandler(async () => await makeGetReq(appConfig.domain + '/admin/scores/' + id, {token}));

export const updateUserData = async (token, data) => await defaultHttpResHandler(async () => await makePostReq(appConfig.domain + '/admin/client', data, {
    token,
    data: true
}));

export const updateUserScores = async (token, data) => await defaultHttpResHandler(async () => await makePostReq(appConfig.domain + '/admin/scores', data, {
    token,
    data: true
}));

export const deleteUser = async (token, id) => await defaultHttpResHandler(async () => await makeDeleteReq(appConfig.domain + '/admin/client/' + id, null, {
    token
}));

export const fetchForms = async (token, page, limit, to, from, sort) => await defaultHttpResHandler(async () => await makeGetReq(appConfig.domain + '/admin/discussion?page=' + page + '&limit=' + limit + '&to=' + to + '&from=' + from + '&sort=' + sort, {token}));

export const fetchSupportMessages = async (token, page) => await defaultHttpResHandler(async () => await makeGetReq(`${appConfig.domain}/support-message?page=${page}`, {token}));

