<template>
  <div
      class="dialog"
      ref="dialog"
      id="dialog"
      @scroll="loadMoreMessage"
      v-if="messages"
  >
    <div class="messages" v-for="item in messages" :key="item.id">
      <a-card style="cursor: pointer" @click="$router.push({name: 'separateUser', params: {id: item.userId}})">
        <template #title>
          {{ item.name }}
        </template>

        <template #extra>
          {{ new Date(item.createDate).toLocaleDateString('ru-RU', dateOptions) }}
        </template>
        {{ item.body ? item.body : 'Нет сообщения' }}
      </a-card>
    </div>
  </div>
</template>

<script>

export default {
  name: "UsersChatPage",
  computed: {
    messages() {
      return this.$store.getters.messages;
    },
    socket() {
      return this.$store.getters.socket;
    },
    pagination() {
      return this.$store.getters.pagination;
    }
  },
  watch: {
    messages(val) {
      if (val && this.firstLoad) {
        this.scrollMessage();
        this.firstLoad = false;
      }
    },
  },
  data() {
    return {

      dateOptions: {
        hour: 'numeric',
        minute: 'numeric'
      }
    }
  },
  methods: {
    waitForConnection(callback, interval) {
      if (this.socket.readyState === 1) {
        callback();
      } else {
        let that = this;
        setTimeout(function () {
          that.waitForConnection(callback, interval);
        }, interval);
      }
    },
    fetchMessage() {
      this.$store.dispatch("fetchMessages");
    },
    scrollMessage() {
      if (this.$refs.dialog) {
        setTimeout(() => {
          this.$refs.dialog.scrollTop = this.$refs.dialog.scrollHeight + 100;
        }, 150);
      }
    },
    loadMoreMessage() {
      let el = this.$refs.dialog;
      if (el.scrollTop === 0 && this.pagination) {
        this.$store.commit('loadMoreMessages')
      }
    },
  },

  beforeMount() {
    this.$store.commit('createSocket');
  },
  mounted() {
    this.waitForConnection(this.fetchMessage, 1000);
    document.addEventListener('scrollMessages', this.scrollMessage);
  },
  beforeUnmount() {
    this.$store.commit('closeSocket');
    document.removeEventListener('scrollMessages', this.scrollMessage);
  },
  beforeRouteLeave() {
    this.$store.commit('closeSocket');
    document.removeEventListener('scrollMessages', this.scrollMessage);
  },

}
</script>

<style scoped>

.dialog {

  overflow: auto;
  display: flex;
  height: calc(100vh - 94px);
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1.11vw;
  scrollbar-width: thin;
  padding: 0 0 40px 0;
}

.messages {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.messages-header {
  display: flex;
  justify-content: flex-start;
}


</style>